import { render, staticRenderFns } from "./BreathingDetail.vue?vue&type=template&id=8781452e&scoped=true&"
import script from "./BreathingDetail.vue?vue&type=script&lang=js&"
export * from "./BreathingDetail.vue?vue&type=script&lang=js&"
import style0 from "./BreathingDetail.vue?vue&type=style&index=0&media=print&lang=css&"
import style1 from "./BreathingDetail.vue?vue&type=style&index=1&id=8781452e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8781452e",
  null
  
)

export default component.exports